import React, { useState, useEffect } from 'react';
// import Button from './atoms/Button';
import { FaCoffee } from 'react-icons/fa';
import Hero from './Hero';
import Development from './Development';
import Projects from './Projects';
import ContactSection from './Contact';
import Navigation from './Navigation';
import Footer from './Footer';

import{InMemoryCache,
    ApolloClient,
    gql
    } from '@apollo/client'
    
    const client = new ApolloClient({
      cache:  new InMemoryCache(),
      uri: " https://cleaves.code14-labs.tech/graphql",
      fetchOptions: {
        mode: 'no-cors',
      }
    })

const Home = () => {


  const [heroImage , setHeroImage] = useState("");
  const [emailImage , setEmailImage] = useState("");
  const [heroMessage , setHeroMessage] = useState("");
  const [heroSubMessage , setHeroSubMessage] = useState("");
  const [heroBrochure , setHeroBrochure] = useState("");
  const [developmentName , setDevelopmentName] = useState("");
  const [developmentDescription , setDevelopmentDescription] = useState("");
  const [developmentHero , setDevelopmentHero] = useState("");
  const [devPack , setDevPack] = useState([]);
  const [developmentList , setDevelopmentList] = useState([]);
  const [conceptList , setConceptList] = useState([]);
  const [devPackb , setDevPackb] = useState([]);






  useEffect( () => {

    

     
    client
  .query({
    query: gql`
    query{
    pages {
      edges {
        node {
          homepage {
            heroBrochure
            heroHeading
            heroSubheading
            heroimages {
              mediaItemUrl
            }
            emailBackground{
              mediaItemUrl
            }
          }
        }
      }
    }
  }
    `,
  })
  .then(async result => { 

        console.log(result.data.pages)
        setHeroBrochure(result.data.pages.edges[3].node.homepage.heroBrochure)
        setHeroMessage(result.data.pages.edges[3].node.homepage.heroHeading)
        setHeroSubMessage(result.data.pages.edges[3].node.homepage.heroSubheading)
        setHeroImage(result.data.pages.edges[3].node.homepage.heroimages.mediaItemUrl)
        setEmailImage(result.data.pages.edges[3].node.homepage.emailBackground.mediaItemUrl)
        
         

  })


  




  client
  .query({
    query: gql`
    query{
    pages {
      edges {
        node {
          develop {
            developmentList {
              ammenitiesElectricity
              ammenitiesFitness
              ammenitiesParking
              ammenitiesSecurity
              developmentDescription
              developmentLocation
              developmentName
              fieldGroupName
              developmentScope {
                floorPlan
                roomNumber
              }
              developmentHero {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  }
    `,
  })
  .then(async result => { 
    let devArray = []
     let obj
        console.log(result.data.pages.edges[2].node.develop.developmentList[0])        
        setDevelopmentList(result.data.pages.edges[2].node.develop.developmentList)
        console.log(developmentList)
        console.log(developmentList.length)
        for(let x=0 ; x < result.data.pages.edges[2].node.develop.developmentList.length ; x++ ){ 
        

         obj = {
           developmentName: result.data.pages.edges[2].node.develop.developmentList[x].developmentName,
           developmentLocation: result.data.pages.edges[2].node.develop.developmentList[x].developmentLocation,
           developmentDescription: result.data.pages.edges[2].node.develop.developmentList[x].developmentDescription,
           developmentHero: result.data.pages.edges[2].node.develop.developmentList[x].developmentHero.mediaItemUrl
        }      

       
        devArray.push(obj)
        }

       setDevPack(devArray)
    

        
  })



}, [])








if(developmentList.length){
  return (
    <>
    <Navigation />
        <Hero banner={heroImage} bannerMessage={heroMessage} bannerSubMessage={heroSubMessage} bannerBrochure={heroBrochure} />
        <Development developments={devPack} />
        <Projects concepts={devPackb}/>
        <ContactSection banner={emailImage} />
        <Footer brochure={heroBrochure}/>
    </>
);

}



};

export default Home;
