// state.ts
import { proxy } from 'valtio';

import parking from './assets/icons/parking.png'
import electricity from './assets/icons/electricity.png'
import security from './assets/icons/guard.png'
import fitness from './assets/icons/exercise.png'
import swimmingPool from './assets/icons/swimming-pool.png'



// import floorPlan from './assets/'



export const imageIcons = proxy({
    electricity: electricity,
    parking: parking,
    security: security,
    fitness: fitness,
    swimmingPool: swimmingPool
})

