// Description.jsx
import React from 'react';

const Description = () => {
    return (
        <div className="md:w-1/2 sm:w-1 lg:w-1/2 text-white text-center mb-8 px-2">
            {/* Description Paragraph */}
            <p className="text-lg">
               Have a look at our future plans for developments across the country. 
            </p>
        </div>
    );
};

export default Description;
