import React, { useState, useEffect } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
// import Button from './atoms/Button';
import { FaCoffee } from 'react-icons/fa';

const Hero = (props) => {

  const [isScrolledDown, setIsScrolledDown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 0) {
        setIsScrolledDown(true);
      } else {
        setIsScrolledDown(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='relative'>
      <img
        className='w-full longHeight object-cover'
        alt='banner-img'
        src={props.banner}
      />
      {/* <video
        className='w-full h-full object-cover'
        src={beachVid}
        autoPlay
        loop
        muted
      /> */}
      <div className='absolute top-0 left-0 w-full longHeight'></div>
      {!isScrolledDown && (
        <div className={`absolute topPosition left-0 w-full h-full flex flex-col justify-center items-center text-center text-gray-600 px-4 sm:px-10 mb-14 ${isScrolledDown ? 'hidden' : 'fadeIn'}`}>
          <div className='bg-green-600/30 py-4 px-10 sm:px-1622222'>
            <h1 className='text-2xl sm:text-4xl'> {props.bannerMessage} </h1>
          </div>
          
          <p className='py-4 sm:text-lg md:text-sm font-bold'>
            {props.bannerSubMessage}
          </p>
          
          <div className='w-full relative flex justify-center items-center'>
            {/* <Button color="black" size="small" variant="block" textColor='green-700'>
              Download Brochure
            </Button> */}
            <a href={props.bannerBrochure} className="btn-white text-green-800 px-2.5 py-1.5 text-sm font-bold"> Download Brochure </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Hero;
