import React from "react";
import './Card.css'

interface CardProps {
    title: string;
    image: string;
}

const Card: React.FC<CardProps> = ({ title, image }) => {
    return (

        <div className="relative p-6 max-w-sm mx-auto flex flex-col justify-center items-center bg-white rounded-lg shadow-lg shadow-custom">
            <img src={image} alt={title} className="icon h-7" />
            <span className="caption font-serif text-base text-green-800 pt-2">{title}</span>
        </div>
    )
}

export default Card;
