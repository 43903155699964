import React, { useState, useEffect } from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

import{InMemoryCache,
    ApolloClient,
    gql
    } from '@apollo/client'
    
    const client = new ApolloClient({
      cache:  new InMemoryCache(),
      uri: " https://cleaves.code14-labs.tech/graphql",
      fetchOptions: {
        mode: 'no-cors',
      }
    })

const Footer = (props) => {

    const [devPack , setDevPack] = useState([]);
    const [developmentList , setDevelopmentList] = useState([]);
    let devArray = []

    useEffect( () => {

    

      client
      .query({
        query: gql`
        query{
        pages {
          edges {
            node {
              develop  {
                developmentList {
                  ammenitiesElectricity
                  ammenitiesFitness
                  ammenitiesParking
                  ammenitiesSecurity
                  developmentDescription
                  developmentLocation
                  developmentName
                  fieldGroupName
                  developmentScope {
                    floorPlan
                    roomNumber
                  }
                  developmentHero {
                    mediaItemUrl
                  }
                }
              }
            }
          }
        }
      }
        `,
      })
      .then(async result => { 
         
         let obj
            console.log(result.data.pages.edges[2].node.develop.developmentList[0])        
            setDevelopmentList(result.data.pages.edges[2].node.develop.developmentList)
            console.log(developmentList)
            console.log(developmentList.length) 

            if (devArray.length != 4){
            for(let x=0 ; x < 4 ; x++ ){ 
            
    
             obj = {
               developmentName: result.data.pages.edges[2].node.develop.developmentList[x].developmentName,
            }      
    
           
            devArray.push(obj)
            }
    
        }
           setDevPack(devArray)
           console.log(devPack)
        
    
            
      })
    
    
    
    }, [])



    let footerLink

    if( devPack.length > 1){ 
            console.log(devPack[0].developmentName)

        footerLink = devPack.map((pack) => 
           
            <li><a href={`/property/${pack.developmentName}`}>{pack.developmentName}</a></li>
    
        )
    }


   

    return (
        <footer className="bg-white text-grey-800 py-8">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-4 ">
                    {/* Column 1: About Our Company */}
                    <div>
                        <h4 className="text-lg mb-4 font-bold">About our company</h4>
                        <p className='text-sm'>Cleave is a property development company<br/> specialize in building affordable aesthetic and <br></br>functional homes for modern families.</p>
                    </div>

                    {/* Column 2: Administrative Links */}
                    <div>
                        <h4 className="text-lg font-bold mb-4"> Administrative Link </h4>
                        <ul className='text-sm'>
                            <li><a href={props.brochure}>Brochure</a></li>
                           
                        </ul>
                    </div>

                    {/* Column 3: Development Links */}
                    <div>
                        <h4 className="text-lg font-bold mb-4"> Developments </h4>
                        <ul className='text-sm'>
                          {footerLink}
                           
                        </ul>
                    </div>

                    {/* Column 4: Social Media Icons */}
                    <div>
                        <h4 className="text-lg font-bold mb-4 text-green-800"> Follow Us </h4>
                        <div className="flex ">
                            <FaInstagram size={30} />
                            <FaLinkedin size={30} />
                        </div>
                    </div>
                </div>
                <div className="sign text-center"> 
                <h6 className="mt-2 text-sm text-grey-300"> Web Design and Development by iNspire </h6>
                </div>
            </div>
        </footer>
    );

}

export default Footer;
