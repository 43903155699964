import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import background from '../assets/banner.png';
import OwlCarousel from 'react-owl-carousel';
import Header from './atoms/Header';
import Description from './atoms/Description';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { InMemoryCache, ApolloClient, gql } from '@apollo/client';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: "https://cleaves.code14-labs.tech/graphql",
  fetchOptions: {
    mode: 'no-cors',
  }
});

const Projects = () => {
  const [devPackb, setDevPackb] = useState([]);

  useEffect(() => {
    client.query({
      query: gql`
        query {
          pages {
            edges {
              node {
                concepts {
                  conceptList {
                    developmentHero {
                      mediaItemUrl
                    }
                    developmentLocation
                    developmentName
                  }
                }
              }
            }
          }
        }
      `,
    }).then(result => {
      const projects = result.data.pages.edges[0].node.concepts.conceptList.map(concept => ({
        developmentName: concept.developmentName,
        developmentLocation: concept.developmentLocation,
        developmentHero: concept.developmentHero.mediaItemUrl
      }));
      setDevPackb(projects);
    });
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  const [overlayHeight, setOverlayHeight] = useState('60vh');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setOverlayHeight(window.innerWidth <= 768 ? '70vh' : '60vh');
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const imageHeight = `calc(${overlayHeight} - 4rem)`;

  return (

 
    <div className="relative h-screen">
      <div className="absolute inset-0" style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className="absolute inset-x-0 top-0 bottom-0 bg-green-800 bg-opacity-90" style={{ height: overlayHeight }}></div>
      </div>
      <div className="absolute inset-0 flex flex-col justify-center items-center text-white md:mt-3 sm:mt-2 px-5 py-8">
        <Header color={`white`} text={`Endorse your Favorite Concept`} hrColor={`white`} />
        <Description />
        {isMobile ? (
          <OwlCarousel className="owl-theme" loop margin={10} items={1} dotClass="owl-dot" dotsEach>
            {devPackb.map(project => (
              <div key={project.developmentName} className="item flex justify-center items-center bg-white rounded-lg shadow-md" style={{ marginTop: '2vh' }}>
                <div className="flex flex-col items-center">
                  <img src={project.developmentHero} alt="" className="w-full rounded-t-lg" style={{ maxHeight: imageHeight }} />
                  <div className="flex flex-col items-center mt-4 docs">
                    <h3 className="text-2xl text-gray-800 font-extrabold">{project.developmentName}</h3>
                    <p className="text-sm text-gray-800 font-extrabold">{project.developmentLocation}</p>
                    <Link to={`/concept/property/${project.developmentName}`} className="bg-green-800 text-white mt-2 rounded-xl px-2.5 py-1.5 text-xs font-bold"> Endorse </Link>
                    <a href="#" className="text-green-800 mt-1 py-2 font-serif text-sm">Read Project Plan</a>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        ) : (
          <div className="overflow-y-auto">
            <div className="flex justify-center items-end mt-auto">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 w-full px-5">
                {devPackb.map(project => (
                  <div key={project.developmentName} className="flex flex-col items-center bg-white rounded-lg mx-5 shadow-md">
                    <img src={project.developmentHero} alt="" className="w-full rounded-t-lg" style={{ maxHeight: imageHeight }} />
                    <div className="flex flex-col items-center mt-4">
                      <h3 className="text-2xl text-gray-800 font-extrabold">{project.developmentName}</h3>
                      <p className="text-sm text-gray-800 font-bold">{project.developmentLocation}</p>
                      <Link to={`/concept/property/${project.developmentName}`} className="bg-green-800 text-white mt-2 rounded-xl px-2.5 py-1.5 text-lg font-bold"> Endorse </Link>
                      <a href="#" className="text-green-800 mt-1 py-2 font-serif text-sm">Read Project Plan</a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className='m-5' style={{ maxHeight: `15vh` }}>
          <Link to="/concept" className="bg-green-800 text-white mt-2 rounded-xl px-2.5 py-1.5 text-lg font-bold"> See all concepts </Link>
        </div>
      </div>
    </div>

  );
};

export default Projects;
