import React, { useState, useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import background from '../assets/banner.png'
import Development from './Development';
import Projects from './Projects';
import ContactSection from './Contact';
import Header from './atoms/Header';
import Navigation from './Navigation';
import Footer from './Footer';

import{InMemoryCache,
    ApolloClient,
    gql
    } from '@apollo/client'

    
    const client = new ApolloClient({
      cache:  new InMemoryCache(),
      uri: " https://cleaves.code14-labs.tech/graphql",
      fetchOptions: {
        mode: 'no-cors',
      }
    })


const Concepts = () => {

  

    const [isMobile, setIsMobile] = useState(false);
    const [overlayHeight, setOverlayHeight] = useState('60vh')
    const [conceptList , setConceptList] = useState([]);
    const [devPack , setDevPack] = useState([]);

    useEffect(() => {



        client
        .query({
          query: gql`
          query{
          pages {
            edges {
              node {
                concepts {
                    conceptList {
                      developmentHero {
                        mediaItemUrl
                      }
                      developmentLocation
                      developmentName
                  
                    }
                  }
              }
            }
          }
        }
          `,
        })
        .then(async result => { 
           let obj
              console.log(result.data.pages.edges[0].node.concepts.conceptList)        
              setConceptList(result.data.pages.edges[0].node.concepts.conceptList) 
             
              if(devPack.length <= 0 ){ 
              for(let x=0 ; x <= result.data.pages.edges[0].node.concepts.conceptList.length - 1; x++ ){ 
                   console.log('looped')
                obj = {
                  developmentName: result.data.pages.edges[0].node.concepts.conceptList[x].developmentName,
                  developmentLocation: result.data.pages.edges[0].node.concepts.conceptList[x].developmentLocation,
                  developmentDescription: result.data.pages.edges[0].node.concepts.conceptList[x].developmentDescription,
                  developmentHero: result.data.pages.edges[0].node.concepts.conceptList[x].developmentHero.mediaItemUrl
                    }     
                    
                  
                        devPack.push(obj)
              
                 
               } 

            }
                

      
        console.log(devPack)            
        })


        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            (window.innerWidth <= 768) ? setOverlayHeight('70vh') : setOverlayHeight('60vh');
        };


        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);




    })

    const imageHeight = `calc(${overlayHeight} - 4rem)`;


    if(conceptList.length){ 
        return (
            <>
            <Navigation />
                <section className='max-w-[1440px] mx-auto lg:h-screen px-2 py-8' style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className='grid lg:grid-cols-4'>
                        <div className='lg:col-span-4 mt-14 py-5 items-center justify-items-center'>
                            {/* <Header color={`green-800`} text={`Our Development`} hrColor={`green`} /> */}
                            <div className="text-center font-black text-2xl tracking-widest text-green-800">Explore our ongoing Developments</div>
                            <hr className="mx-auto my-4 w-1/12 border-t-2 border-green-800" />
                            <p className='py-4 font-serif leading-5 text-gray-700 text-center'>
                                Make an outright purchase or pay small small
                            </p>
                        </div>
    
                        <div className="overflow-y-auto lg:col-span-4">
                            <div className="flex justify-center items-end mt-auto ">
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                                    {devPack.map(project => (
                                        <div className="flex flex-col items-center bg-white rounded-lg mx-10 shadow-md">
                                            <img src={project.developmentHero} alt={project.developmentName} className="w-full rounded-t-lg" style={{ maxHeight: imageHeight }} />
                                            <div className="flex flex-col items-center mt-4">
                                                <h3 className="text-2xl text-gray-800 font-extrabold">{project.developmentName}</h3>
                                                <p className="text-sm text-gray-800 font-bold font-extra-bold">{project.developmentLocation}</p>
                                                <Link to={`/concept/property/${project.developmentName}`} className="bg-green-800 text-white mt-2 rounded-xl px-2.5 py-1.5 text-xs font-bold"> Explore</Link>
                                                <a href="#" className="text-green-800 mt-1 py-2 font-serif text-sm"><Link to={`/property/${project.id}`}>Read Project Plan</Link></a>
                                            </div>
                                        </div>
                                    ))}
                                </div>  
                            </div>
                        </div>
                        
                    </div>
                </section>
                <Footer />
            </>
        );
    }

};

export default Concepts;
