import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { imageIcons } from '../state';
import Header from './atoms/Header';
import Card from './atoms/Card';
import Navigation from './Navigation';
import Footer from './Footer';
import './atoms/Card.css';
import Description from './atoms/Description';
import {
  InMemoryCache,
  ApolloClient,
  gql
} from '@apollo/client'

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: " https://cleaves.code14-labs.tech/graphql",
  fetchOptions: {
    mode: 'no-cors',
  }
})

const Property = () => {
  const params = useParams()
  const navigate = useNavigate();

  const { electricity, parking, security, fitness, swimmingPool } = useSnapshot(imageIcons);
  const [developmentName, setDevelopmentName] = useState("")
  const [developmentDescription, setDevelopmentDescription] = useState("")
  const [secure, setSecure] = useState("")
  const [electric, setElectric] = useState("")
  const [gym, setGym] = useState("")
  const [park, setPark] = useState("")
  const [swim, setSwim] = useState("")
  const [devScope, setDevScope] = useState([])
  const [devImages, setDevImages] = useState([])
  const [developmentHero, setDevelopmentHero] = useState([])







  client
    .query({
      query: gql`
      query{
           pages {
                edges {
                  node {
              
                    develop {
                        developmentList {
                            ammenitiesFitness
                            ammenitiesParking
                            ammenitiesSecurity
                            ammenitiesElectricity
                          developmentHero {
                            mediaItemUrl
                          }
                          developmentLocation
                          developmentName
                          developmentImages {
                            image {
                              mediaItemUrl
                            }
                          }
                          developmentScope {
                            roomNumber
                            floorPlan
                            scopeImage{
                              mediaItemUrl
                            }
                          }
                        }
                      }
                  }
                }
              }
    }
      `,
    })
    .then(async result => {

      console.log(result.data.pages.edges[2].node.develop.developmentList)


      // setboardLamContent(result.data.pages.edges[1].node.develop.developmentList)


      for (let i = 0; i <= result.data.pages.edges[2].node.develop.developmentList.length - 1; i++) {
        console.log(params.id)
        console.log(params.id.length)
        console.log(result.data.pages.edges[2].node.develop.developmentList[i].developmentName)

        let devName = result.data.pages.edges[2].node.develop.developmentList[i].developmentName


        if (devName === params.id) {
          console.log("rann")

          setDevelopmentName(result.data.pages.edges[2].node.develop.developmentList[i].developmentName)
          setDevelopmentDescription(result.data.pages.edges[2].node.develop.developmentList[i].developmentDescription)
          setDevelopmentHero(result.data.pages.edges[2].node.develop.developmentList[i].developmentHero.mediaItemUrl)
          setDevScope(result.data.pages.edges[2].node.develop.developmentList[i].developmentScope)
          setDevImages(result.data.pages.edges[2].node.develop.developmentList[i].developmentImages)
          setSecure(result.data.pages.edges[2].node.develop.developmentList[i].ammenitiesSecurity)
          setGym(result.data.pages.edges[2].node.develop.developmentList[i].ammenitiesFitness)
          setElectric(result.data.pages.edges[2].node.develop.developmentList[i].ammenitiesElectricity)
          setPark(result.data.pages.edges[2].node.develop.developmentList[i].ammenitiesParking)
          setSwim(result.data.pages.edges[2].node.develop.developmentList[i].ammenitiesSwimmingPool)

          return true

        }

      }
    })




  const imageHeight = `calc(60vh - 4rem)`;

  return (
    <>
    <Navigation />
      <section>
        {/* Banner with property title and description */}
        <div className="bg-green-800 text-white py-4 px-6">
          <h2 className="text-2xl font-bold">Welcome to {developmentName}</h2>
          <p className="text-sm"></p>
        </div>

        {/* Main image */}
        <img src={developmentHero} alt={developmentName} className="w-full h-[60vh] object-cover" />

        {/* Image icons with captions */}
        <div className="flex justify-center items-center md:justify-evenly my-6">

          {electric && (<Card image={electricity} title="Electricity" />)}
          {park && (<Card image={parking} title="Parking" />)}
          {secure && (<Card image={security} title="Security" />)}
          {gym && (<Card image={fitness} title="Fitness" />)}
          {swim && (<Card image={swimmingPool} title="Swimming Pool" />)}
        </div>

      </section>
      <section className='py-5'>
        <div className='mx-2'>
          <Header text={`Want to live at ${developmentName} ?`} color={`green-800`} hrColor={`green-800`} align={`center`} />
          <p className="text-sm text-center"></p>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-4">
            {devScope.map((plan, index) => (
              <div key={index} className="bg-white rounded-2xl mx-4 shadow-md">
                <img src={plan.scopeImage.mediaItemUrl} alt="" className="w-full h-auto rounded-t-lg" />
                <div className="rounded-t-md bg-green-900 flex flex-col justify-center items-center">
                  <p className="text-sm text-white">{plan.roomNumber} bedroom Apartment</p>

                  <a href={plan.floorPlan} className="border border-white text-white text-xs rounded-full px-2 py-1 mb-2 transition duration-300 hover:bg-white hover:text-green-800">
                    See Floor Plan
                  </a>
                </div>

              </div>
            ))}
          </div>

        </div>

      </section>

      <section className='mt-5'>
        <Header text={`Experience ${developmentName}'s Aesthetics`} color={`green-800`} hrColor={`transparent`} align={`center`} />
        <div className="flex flex-wrap justify-center">
          {devImages.map((images, index) => (
            <div key={index} className="w-1/2">
              <img src={images.image.mediaItemUrl} alt="" className="w-full h-[80vh] object-cover" />

              {/* <img src={image.image} alt={image.caption} className="w-full h-70vh object-cover"  /> */}
              {/* <p className="text-center">{image.caption}</p> */}
            </div>
          ))}
        </div>

      </section>
      <Footer />
    </>
  );
}

export default Property;
