import React, {useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import Navigation from './Navigation';
import Footer from './Footer';

import{InMemoryCache,
    ApolloClient,
    gql
    } from '@apollo/client'

    
    const client = new ApolloClient({
      cache:  new InMemoryCache(),
      uri: " https://cleaves.code14-labs.tech/graphql",
      fetchOptions: {
        mode: 'no-cors',
      }
    })


const Bulletin = () => {

    const [bulletinList, setBulletinList] = useState([])
    const [bulletinName, setBulletinName] = useState("")
    const [bulletinContentA, setBulletinContent] = useState("")
    const [bulletinHero, setBulletinHero] = useState("")
    const [bulletinContentB, setBulletinContentB] = useState("")
    const [bulletinImages, setBulletinImages] = useState("")

    useEffect(() => {

        client
        .query({
          query: gql`
          query{
            allBulletin {
                edges {
                  node {
                    id
                    bulletin {
                      bulletinContent
                      bulletinImage {
                        mediaItemUrl
                      }
                      bulletinName
                    }
                  }
                }
              }
        }
          `,
        })
        .then(async result => { 
       
              console.log(result.data.allBulletin.edges[0])   
           
             setBulletinList(result.data.allBulletin.edges) 
    
      
      
        })

    })

    const navigate = useNavigate();
 
    return (
      <div>
        <Navigation />
        <section>
            {/* Banner with property title and description */}
            <div className="bg-green-800 text-white py-4 px-6">
                <h2 className="text-2xl font-bold">Bulletin</h2>
                <p className="text-sm">Get the Latest Updates on all Developments</p>
            </div>

            {bulletinList.map(bulletin => (
                <>
                    <div className="max-w-sm w-full lg:max-w-full lg:flex my-4 px-4">
                        <div className="h-48 lg:h-auto lg:w-1/3 flex-none rounded-t lg:rounded-t-none lg:rounded-l overflow-hidden">
                            <img src={bulletin.node.bulletin.bulletinImage.mediaItemUrl} alt="Bulletin" className="object-cover w-full h-full" />
                        </div>
                        <div className="flex-1 flex flex-col justify-between border-gray-400 lg:border-l-0 bg-gray-200 rounded-b lg:rounded-b-none lg:rounded-r p-4">
                            <div className="mb-auto">
                                <div className="text-green-800 font-bold text-xl mb-2 font-sans">{bulletin.node.bulletin.bulletinName}</div>
                                <div className="text-green-800 tracking-wider font-serif text-base mb-4">{bulletin.node.bulletin.bulletinContent}.</div>
                                <Link to={`/bulletin/${bulletin.node.id}`}>
                                    <button className="bg-green-800 text-white rounded-xl px-4 py-2 text-xs font-bold">Read More</button>
                                </Link>
                            </div>
                        </div>
                    </div>

                </>
            ))}


        </section>
        <Footer />
      </div>

    )
}

export default Bulletin