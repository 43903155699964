import React from 'react';
import Header from './atoms/Header';

const ContactSection = (props) => {

    return (

    
        <section className="relative h-screen bg-cover bg-center justify-center items-center" style={{ backgroundImage: `url(${props.banner})` }}>
            <div className="absolute inset-0 bg-black opacity-50"></div>
            <div className="absolute inset-0 flex justify-center items-center">
                <div className="bg-green-800 bg-opacity-75 p-8 rounded-lg justify-center items-center w-full max-w-screen-md mx-4">
                    <form>
                        <div className="mb-4">
                            <div className="flex flex-col items-center justify-center my-8">
                                <h2 className="text-3xl text-white text-center font-bold mb-4 tracking-widest">Sign Up for our Quarterly Bulletin</h2>
                                <p className="font-serif text-white leading-5 tracking-widest text-center">
                                    Receive updates on new projects, current developments, sales offers and discounts <br /> from Cleave
                                </p>
                            </div>

                            <div className="flex mb-8 flex-col sm:flex-row">
                                <input
                                    type="text"
                                    className="bg-transparent border border-white focus:border-green-600 focus:outline-none flex-1 sm:mr-2 mb-4 sm:mb-0 px-3 py-2 placeholder-gray-300 text-white"
                                    placeholder="First Name"
                                />
                                <input
                                    type="text"
                                    className="bg-transparent border border-white focus:border-green-600 focus:outline-none flex-1 sm:ml-2 px-3 py-2 placeholder-gray-300 text-white"
                                    placeholder="Last Name"
                                />
                            </div>
                            <div className="flex mb-8 flex-col sm:flex-row">
                                <input
                                    type="tel"
                                    className="bg-transparent border border-white focus:border-green-600 focus:outline-none flex-1 sm:mr-2 mb-4 sm:mb-0 px-3 py-2 placeholder-gray-300 text-white"
                                    placeholder="Phone"
                                />
                                <input
                                    type="email"
                                    className="bg-transparent border border-white focus:border-green-600 focus:outline-none flex-1 sm:ml-2 px-3 py-2 placeholder-gray-300 text-white"
                                    placeholder="Email"
                                />
                            </div>

                            <div className='flex flex-col my-8 items-center'>
                                <button type="submit" className="bg-white hover:text-white hover:bg-green-700 text-green-800 font- py-2 px-2.5 rounded-xl focus:outline-none focus:shadow-outline px-8">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>

    );
}

export default ContactSection;
