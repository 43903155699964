import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    FaFacebook,
    FaTwitter,
    FaInstagram,
    FaPinterest,
    FaYoutube,
    FaPerson,
    FaBars,
    FaPhone,
} from 'react-icons/fa6';
import {
    FaSearch,
    FaTimes,
} from 'react-icons/fa';
import logo from '../assets/cleave_logo.png'

const Navigation = () => {
    const [navVisible, setNavVisible] = useState(true);
    const [logoVisible, setLogoVisible] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const isVisible = currentScrollPos < 50; // Change 50 to your desired scroll threshold

            // setNavVisible(isVisible);
            // setLogoVisible(isVisible);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleNavToggle = () => {
        setNavVisible(!navVisible);
        setLogoVisible(!logoVisible);
    };

    return (
        <>
            <aside data-pushbar-id="left" data-pushbar-direction="left" className={`absolute left-0 top-0 w-full bg-white px-4 py-7 flex flex-col md:hidden`}>
                <Link to="/"><img src={logo} alt='cleave-logo' style={{ maxHeight: '100px' }} /></Link>

                <ul>
                    <li className='border-b'><Link to="/concept">Concepts</Link></li>
                    <li className='border-b'><Link to="/development">Developments</Link></li>
                    <li className='border-b'><Link to="/sustainability">Sustainability</Link></li>
                    <li className='border-b'><Link to="#">Investor relations</Link></li>
                    <li className='border-b'><Link to="/bulletin">Bulletin</Link></li>
                </ul>
                {/* <div className='flex flex-col'>
                    <button className='my-6'>Search</button>
                    <button>Account</button>
                </div> */}
                <div className='flex justify-between my-6'>
                    <FaFacebook className='icon' />
                    <FaTwitter className='icon' />
                    <FaYoutube className='icon' />
                    <FaPinterest className='icon' />
                    <FaInstagram className='icon' />
                </div>
            </aside>
            <div className={`flex w-full justify-between items-center h-20 px-4 ${ location.pathname === '/' ? 'absolute' : 'relative' } z-10 text-gray-700 font-serif text-sm ${navVisible ? '' : 'bg-green-500'}`}>
                <div className='flex items-center'>
                    <ul className='hidden md:flex ml-4'>
                        <li><Link to="/concept">Concepts</Link></li>
                        <li><Link to="/development">Developments</Link></li>
                        <li><Link to="/sustainability">Sustainability</Link></li>
                    </ul>
                </div>
                <Link to="/"><img src={logo} alt='cleave-logo' style={{ maxHeight: '100px' }} /></Link>
                <ul className='hidden md:flex'>
                    <li>Investor relations</li>
                    <li><Link to="/bulletin">Bulletin</Link></li>
                    <li>
                        <button className="btn-green text-white-800 px-2.5 py-1.5 text-xs font-bold flex items-center">
                            <FaPhone size={16} className="mr-1" />
                            Call Now
                        </button>
                    </li>
                </ul>

                {/* Hamburger */}
                {/* <div onClick={handleNavToggle} className='md:hidden z-10'>
                    {navVisible ? <FaBars size={20} /> : <FaTimes size={20} />}
                </div> */}
                <div className='md:hidden z-10' data-pushbar-target="left">
                    {navVisible ? <FaBars size={20} /> : <FaTimes size={20} />}
                </div>

                {/* Mobile menu dropdown */}
                {/* <div
                    onClick={handleNavToggle}
                    className={`absolute left-0 top-0 w-full bg-white px-4 py-7 flex flex-col md:hidden ${navVisible ? 'hidden' : 'flex'}`}
                > */}
                    {/* <Link to="/"><img src={logo} alt='cleave-logo' style={{ maxHeight: '100px' }} /></Link>
                    <ul>
                        <li className='border-b'><Link to="/concept">Concepts</Link></li>
                        <li className='border-b'><Link to="/development">Developments</Link></li>
                        <li className='border-b'><Link to="/sustainability">Sustainability</Link></li>
                        <li className='border-b'><Link to="#">Investor relations</Link></li>
                        <li className='border-b'><Link to="/bulletin">Bulletin</Link></li>
                    </ul> */}
                    {/* <div className='flex flex-col'>
                        <button className='my-6'>Search</button>
                        <button>Account</button>
                    </div> */}
                    {/* <div className='flex justify-between my-6'>
                        <FaFacebook className='icon' />
                        <FaTwitter className='icon' />
                        <FaYoutube className='icon' />
                        <FaPinterest className='icon' />
                        <FaInstagram className='icon' />
                    </div> */}
                {/* </div> */}
            </div>
        </>
        
    );
};

export default Navigation;