import React from 'react'

type Props = {
    color: String,
    text: String,
    hrColor: String
    align?: String,
}

const Header = ({ color, text, hrColor, align = 'left' }: Props) => {
    return (
        // place div content in center of page
        <div className={`text-${color} text-${align} text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-4 mt-4 tracking-wide text-center`}>
            {text}
            <hr className={`w-24 h-4 border-t-4 border-${hrColor}-500 mx-auto my-0`} />
        </div>

    )
}

export default Header